/*! core.scss | Promy | 2018-2019 */

/* ==========================================================================
CSS core
========================================================================== */

@import "partials/colors";
@import "partials/fonts";
@import "partials/layout";
@import "partials/navbar";
@import "partials/sections";
@import "partials/buttons";
@import "partials/forms";
@import "partials/utilities";
@import "partials/responsive";
