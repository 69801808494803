/*! _layout.scss | Promy | 2018-2019 */

/* ==========================================================================
Promy layout
========================================================================== */

body {
    overflow-x: hidden;
    font-family: 'Dosis', sans-serif;
}

.hero {
    color:#000;
    position: relative;
    background-color: #fff;

    .is-hero-caption {
        position: relative;
        z-index: 1;

        .title {
            margin-bottom: 10px !important;
            color: #33628f;
        }

        .subtitle {
            max-width: 450px;
            color: #33628f;
            margin: 0 auto;
        }

        .button-wrap {
            margin-top: 16px;
        }
    }

    .circle-right {
        position: absolute;
        top: -180px;
        right: 0;
        height: 100%;
        display: block;
        opacity: .8;
        z-index: 0;
    }

    .shape-left {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        opacity: .8;
        z-index: 0;
    }
}

.section {
    &.is-grey {
        background: $section-grey;
    }

    &.is-reversed {
        .columns {
            flex-direction: row-reverse;
        }
    }

    &.has-circles {
        position: relative;

        .orange-circle {
            position: absolute;
            right: 35%;
            top: 5%;
            height: 250px;
            width: 250px;
            border-radius: 50%;
            background: $primary-light;
            z-index: 1;
        }

        .orange-bold-circle {
            position: absolute;
            left: 15%;
            bottom: 10%;
            height: 210px;
            width: 210px;
            border-radius: 50%;
            background: $primary-bold;
            z-index: 1;
        }

        .blue-circle {
            position: absolute;
            right: 65%;
            top: 18%;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background: $accent;
        }
    }

    .section-title {
        img {
            display: block;
            width: 40px;
            margin: 0 auto;
        }

        h2 {
            margin: 10px auto;
            text-align: center;
        }

        h3 {
            color: $muted-grey;
        }
    }

    .side-title {
        position: relative;

        .title-number {
            position: absolute;
            top: -70px;
            left: 0;
            font-size: 7rem;
            font-family: 'Dosis', sans-serif;
            font-weight: 700;
            color: $dark-text;
            opacity: .1;
        }
    }

    .side-paragraph {
        color: $muted-grey;
        font-size: 1.3rem;
        max-width: 450px;

        &.is-small {
            font-size: 1rem;
        }

        &.has-margin {
            margin-top: 20px;
        }
    }

    .side-image {
        position: relative;
        z-index: 2;
        transition: all .5s;
        transform: translateX(0);

        &.is-pulled {
            transform: translateX(-80px);
        }
    }

    .shadow-side-image {
        position: absolute;
        top: 10px;
        left: 0;
        display: block;
        height: calc(100% - 20px);
        z-index: 1;
        transform: translateX(60px);
        transition: all .5s;

        &.is-active {
            transform: translateX(-30px);
        }
    }

    .content-wrapper {
        margin-top: 60px;

        &.has-margin-bottom {
            margin-bottom: 60px;
        }
    }
}

.columns {
    .column {
        &.is-relative {
            position: relative;
        }
    }
}

.title {
    font-family: 'Dosis', sans-serif;
    color: $dark-text;

    &.is-light {
        color: $smoke-white;
    }

    &.primary-title {
        color: $primary-light;
    }

    &.grey-title {
        color: $med-grey;
    }
}

.subtitle {
    font-family: 'Dosis', sans-serif;

    &.is-light {
        color: $smoke-white;
    }
}

.footer {
    background-color: #33628f;
    padding: 8rem 1.5rem 4rem 1.5rem;

    .footer-logo {
        max-width: 180px;
    }

    .footer-logo-text {
        margin-top: 16px;
        color: $smoke-white;
        padding-right: 20px;
    }

    .footer-menu {
        li {
            padding: 8px 0;
            color: $smoke-white;

            a {
                color: $smoke-white;
            }
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8rem;

        .copyright-logo {
            display: block;
            max-width: 70px;
        }

        .copyright-text {
            font-weight: 500;
            font-size: .9rem;
            color: $smoke-white;
        }
    }
}
