/*! _fonts.scss | Promy | 2018-2019 */

/* ==========================================================================
Promy fonts
========================================================================== */

@font-face {
    font-family: 'Dosis', sans-serif;
}

@font-face {
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
}
