/*! _navbar.scss | Promy | 2018-2019 */

/* ==========================================================================
Promy navbar
========================================================================== */

.navbar {
    background-color: $white;
    height: 120px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    transition: all .3s;

    .navbar-brand {
        height: 110px;

        .light-logo {
            display: none !important;
            width: 159px !important;
            height: 108px !important;
            max-height:15rem;
        }

        .dark-logo {
            display: block !important;
            width: 159px !important;
            height: 108px !important;
            max-height:15rem;
        }

        .navbar-burger {
            height: 60px;
            width: 60px;
            transition: all .3s;

            span {
                background: $muted-grey;
            }
        }
    }

    .separator {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 32px;
        width: 1.4px;
        margin: 0 16px;
        background: #ccc;
    }

    .navbar-item {
        color: $muted-grey;
        transition: all .3s;

        &.has-hover {
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                -webkit-transform-origin: right center;
                -ms-transform-origin: right center;
                transform-origin: right center;
                height: 1.4px;
                background: $primary-light;
                -webkit-transform: scale(0, 1);
                -ms-transform: scale(0, 1);
                transform: scale(0, 1);
                -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            }

            //Hover state
            &:hover {
                &:before {
                    -webkit-transform-origin: left center;
                    -ms-transform-origin: left center;
                    transform-origin: left center;
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    transform: scale(1, 1);
                }
            }
        }

        .cta-button {
            background: $primary-light;
            border-color: $primary-light;

            &:hover {
                box-shadow: $primary-box-shadow !important;
                opacity: .9;
            }
        }

        &:hover {
            color: $primary;
        }
    }

    &.is-faded {
        height: 120px;
        background-color: transparent;
        box-shadow: none;

        .navbar-brand {
            height: 110px;

            .light-logo {
                display: block !important;
                width: 159px !important;
                height: 108px !important;
                max-height:15rem;
            }

            .dark-logo {
                display: none !important;
                width: 159px !important;
                height: 108px !important;
                max-height:15rem;
            }

            .navbar-burger {
                height: 80px;
                width: 80px;

                span {
                    background: $smoke-white;
                }
            }
        }

        .separator {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            height: 36px;
            width: 1.4px;
            margin: 0 16px;
            background: $smoke-white;
        }

        .navbar-item {
            color: $smoke-white;

            &.has-hover {
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    -webkit-transform-origin: right center;
                    -ms-transform-origin: right center;
                    transform-origin: right center;
                    height: 1.4px;
                    background: $smoke-white;
                    -webkit-transform: scale(0, 1);
                    -ms-transform: scale(0, 1);
                    transform: scale(0, 1);
                    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                    transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                }

                //Hover state
                &:hover {
                    &:before {
                        -webkit-transform-origin: left center;
                        -ms-transform-origin: left center;
                        transform-origin: left center;
                        -webkit-transform: scale(1, 1);
                        -ms-transform: scale(1, 1);
                        transform: scale(1, 1);
                    }
                }
            }

            .cta-button {
                background: $accent;
                border-color: $accent;

                &:hover {
                    box-shadow: $accent-box-shadow;
                    opacity: .9;
                }
            }
        }
    }

    &.is-mobile {
        background-color: $white !important;
        height: 80px !important;

        .navbar-brand {
            height: 80px !important;

            .light-logo {
                display: none !important;
            }

            .dark-logo {
                display: block !important;
            }

            .navbar-burger {
                height: 80px !important;
                width: 80px !important;

                span {
                    background: $muted-grey !important;
                }
            }
        }

        .navbar-item {
            color: $muted-grey !important;
        }
    }

    .navbar-menu {
        .navbar-item {
            text-align: center;
        }

        .button {
            width: 100%;
        }
    }
}
